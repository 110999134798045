import { Button, Input, Typography, Popover } from 'antd';
import PwIcon from './assets/pw-icon.svg';
import { useCallback, useState } from 'react';
import { identifyUserV2 } from 'services/api';
import { useNav } from 'services/urlParams';
import { AppNotification } from 'components/Notification';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function OneTimeCodePage() {
    const [fetching, setFetching] = useState(false);

    const navigate = useNav();
    const handleOneTimeCode = useCallback(
        async (code) => {
            try {
                setFetching(true);
                await identifyUserV2({ token: code, provider: 'passwordless' });
                window?.location.replace('/'); // TODO: next url should be here
            } catch (err) {
                // TODO: display proper error
                AppNotification.error({ description: 'Code is incorrect' });
                console.error(err);
                setFetching(false);
            }
        },
        [setFetching],
    );

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');

    return (
        <div className='w-full h-full flex items-center justify-center'>
            <div className='absolute top-0 left-0'>
                <Button
                    icon={<ArrowLeftOutlined />}
                    type={'default'}
                    className='m-2'
                    onClick={() => navigate('/signin')}>
                    Go Back
                </Button>
            </div>
            <div className='max-w-[300px] w-full flex flex-col items-center'>
                <img
                    src={PwIcon}
                    alt='Perfect Wiki Logo'
                    className={`w-32 h-32`}
                    loading='eager'
                />
                <Typography.Title className='text-center' level={4}>
                    Enter One-Time Code
                </Typography.Title>
                <form
                    className='w-full'
                    onSubmit={(e) => {
                        const codeField =
                            e.target.querySelector('input[type=text]');
                        const code = codeField?.value;

                        code && handleOneTimeCode(code);
                        e.preventDefault();
                        return false;
                    }}>
                    <div className='w-full flex flex-col gap-2 items-center'>
                        <Input
                            disabled={fetching}
                            required
                            autoFocus
                            size='large'
                            type='text'
                            inputMode='numeric'
                            pattern='[0-9]*'
                            minLength={6}
                            autoComplete='one-time-code'
                            maxLength={6}
                            placeholder='Type one-time code here'
                            aria-label='One-Time Code'
                        />
                        <Button
                            type='primary'
                            htmlType='submit'
                            size='large'
                            className='w-full capitalize'
                            loading={fetching}>
                            SUBMIT
                        </Button>
                        <div className='w-full break-words text-gray-400'>
                            We sent a one time code to{' '}
                            <span className='underline decoration-dotted'>
                                {email || 'your email'}
                            </span>
                            , enter it here.
                        </div>
                        <Popover
                            content={
                                <div className='max-w-[250px] break-words'>
                                    <ol className='space-y-2'>
                                        <li>
                                            Verify if there are any typos in
                                            your email address. You
                                            entered:&nbsp;
                                            <b>{email}</b>
                                        </li>
                                        <li>
                                            Check your <b>SPAM folder</b>; the
                                            message might have been delivered
                                            there
                                        </li>
                                        <li>
                                            If the issue persists, please{' '}
                                            <a
                                                href='https://forms.gle/R9KfVkSQxGuXUE686'
                                                target='_blank'
                                                className='underline'
                                                rel='noreferrer'>
                                                submit a support request
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            }
                            title='Common Issues'
                            trigger={'click'}>
                            <Button type='link' className='p-0'>
                                <span className='underline'>
                                    Click here if you didn't received a code
                                </span>
                            </Button>
                        </Popover>
                    </div>
                </form>
            </div>
        </div>
    );
}



