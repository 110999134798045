import { t } from 'i18next';
import { useEffect, useState } from 'react';
import * as analytics from 'services/analytics';
import { logoutFromSession } from 'services/authorization';
import { useNav } from 'services/urlParams';
import { getUserSession } from 'services/api';
import FullScreenFetching from '../FullScreenFetching';
import { useApplicationContextState } from './hooks';
import { hasSessionId } from 'services/cookies';
import { getMsTeamsContext } from 'services/microsoftTeamsHelpers';
import { removeMsTeamsContextFromLocalStorage } from 'services/localStorage';

async function getAppContext(setApplicationContextState, navigate) {
    const identifiedUser = await getActiveUserAccount();

    if (!identifiedUser) {
        logoutFromSession();
        const currentUrl = window?.location?.href;
        navigate(`/signin?next=${encodeURIComponent(currentUrl)}`, {
            replace: true,
        });
        return;
    }

    analytics.identify(identifiedUser.organization.id, {
        plan: identifiedUser.licenseLimits.planName,
        name: identifiedUser.organization.name,
        domain: identifiedUser.organization.domain,
    });

    if (identifiedUser.licenseLimits.planName === 'FREE_TRIAL') {
        analytics.startSessionRecording();
    }

    if (
        identifiedUser.licenseLimits.planName === 'FREE_TRIAL' &&
        identifiedUser.licenseLimits.trialDaysLeft === 0
    ) {
        navigate('/trial-expired', {});
    }

    setApplicationContextState(identifiedUser);
}

async function getActiveUserAccount() {
    if (!hasSessionId()) {
        return null;
    }

    try {
        const profile = await getUserSession();
        return profile;
    } catch (err) {
        console.error(err);
        return null;
    }
}

async function initTeamsContext() {
    try {
        await getMsTeamsContext();
    } catch (err) {
        console.warn(err);
        removeMsTeamsContextFromLocalStorage();
    }
}

const ApplicationContextProvider = ({ children }) => {
    const { setApplicationContextState } = useApplicationContextState();
    const navigate = useNav();
    const [fetchingMessage, setFetchingMessage] = useState('Initializing...');

    useEffect(() => {
        async function initialize() {
            setFetchingMessage(t('fetching-context', 'Fetching context...'));
            try {
                await Promise.all([
                    getAppContext(setApplicationContextState, navigate),
                    initTeamsContext(),
                ]);

                setFetchingMessage('');
            } catch (err) {
                let message = t('internal-error', 'Internal error');
                const errorMessage = err.message || err || message;

                if (err) {
                    navigate(
                        `/error?message=${encodeURIComponent(errorMessage)}`,
                    );
                    return;
                }

                navigate(`/error?message=${encodeURIComponent(message)}`);
            }
        }

        initialize();
    }, [navigate, setApplicationContextState, setFetchingMessage]);

    if (fetchingMessage) {
        return <FullScreenFetching message={fetchingMessage} />;
    }

    return <>{children}</>;
};

export default ApplicationContextProvider;
