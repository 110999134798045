import { useCallback, useEffect, useState } from 'react';
import { hasSessionId } from 'services/cookies';
import { useNav } from 'services/urlParams';
import { useLocation } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import ReviewerPhoto from './assets/robin.png';
import BackgroundPattern from './assets/background-pattern.png';
import * as analytics from 'services/analytics';

import { AppNotification } from 'components/Notification';
import GoogleAuthButton from './components/GoogleAuthButton';
import FullScreenLoading from 'components/FullScreenFetching';
import { getMsTeamsContext } from 'services/microsoftTeamsHelpers';
import { Space, Input, Button } from 'antd';
import { sendOneTimeCode } from 'services/api';
import { MailOutlined } from '@ant-design/icons';

function FiveStarRating() {
    return (
        <div className='flex items-center'>
            <svg
                className='w-5 h-5 text-[#FDB241]'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
            <svg
                className='w-5 h-5 text-[#FDB241]'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
            <svg
                className='w-5 h-5 text-[#FDB241]'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
            <svg
                className='w-5 h-5 text-[#FDB241]'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
            <svg
                className='w-5 h-5 text-[#FDB241]'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'>
                <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
        </div>
    );
}

function MicrosoftLogo() {
    return (
        <svg
            className='w-auto h-5 mr-4'
            viewBox='0 0 23 23'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 0h23v23H0z' fill='transparent' />
            <path fill='#f35325' d='M1 1h10v10H1z' />
            <path fill='#81bc06' d='M12 1h10v10H12z' />
            <path fill='#05a6f0' d='M1 12h10v10H1z' />
            <path fill='#ffba08' d='M12 12h10v10H12z' />
        </svg>
    );
}

function PasswordlessSignin({ hintEmail = '' }) {
    const navigate = useNav();
    const [isFetching, setFetching] = useState(false);
    const handlePassworldessSignin = useCallback(
        async (email) => {
            try {
                setFetching(true);
                await sendOneTimeCode({ email });
                analytics.startSessionRecording();
                navigate(`/signin/code?email=${email}`);
            } catch (err) {
                console.error(err);
                AppNotification.error({
                    description: `Failed to send one time code to your email. Try again later.`,
                });
                setFetching(false);
            }
        },
        [navigate, setFetching],
    );

    return (
        <form
            onSubmit={(e) => {
                const emailField = e.target.querySelector('input[type=email]');
                const email = emailField?.value;

                email && handlePassworldessSignin(email);
                e.preventDefault();
                return false;
            }}>
            <Space size='small' direction='vertical' className='w-full px-2'>
                <Input
                    autoFocus
                    required
                    name='email'
                    type='email'
                    size='large'
                    className='w-full'
                    placeholder='Type your email'
                    disabled={isFetching}
                    defaultValue={hintEmail}
                />
                <Button
                    size='large'
                    htmlType='submit'
                    className='w-full'
                    loading={isFetching}
                    icon={<MailOutlined />}
                    type='primary'>
                    Sign in with email&nbsp;&rarr;
                </Button>
            </Space>
        </form>
    );
}

function SignInPage({ nextUrl, startFetching, stopFetching, msTeamsContext }) {
    const navigate = useNav();
    const isMsTeams = msTeamsContext !== null;

    const authWithMsTeams = useCallback(async () => {
        try {
            startFetching();
            await microsoftTeams.app.initialize();
            await microsoftTeams.authentication.authenticate({
                url:
                    window.location.origin +
                    '/signin/microsoft/teams/auth-start',
                width: 400,
                height: 400,
            });

            if (nextUrl) {
                window?.location?.replace(nextUrl);
                return;
            }

            window?.location?.replace('/');
        } catch (err) {
            stopFetching();
            throw new Error(err);
        }
    }, [nextUrl, startFetching, stopFetching]);

    const handleMicrosoftAuthClick = useCallback(() => {
        if (isMsTeams) {
            authWithMsTeams();
            return;
        }

        navigate(`/signin/microsoft?next=${encodeURIComponent(nextUrl)}`);
    }, [authWithMsTeams, isMsTeams, navigate, nextUrl]);

    return (
        <section className='h-screen'>
            <div className='h-full lg:flex lg:justify-between'>
                <div className='w-full h-full flex flex-col items-center justify-center'>
                    <h1 className='px-2 text-center text-3xl font-bold dark:text-gray-100 text-gray-900 sm:text-4xl font-pj'>
                        Welcome to Perfect&nbsp;Wiki&nbsp;👋
                    </h1>
                    <p className='px-2 mt-2 md:mt-4 sm:text-lg dark:text-gray-300 text-gray-600 font-pj'>
                        Knowledge base that works where your team does.
                    </p>
                    <div className='max-w-sm mx-auto mt-2 md:mt-10'>
                        <div className='relative'>
                            <div className='absolute -inset-4'>
                                <div
                                    className='w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter'
                                    style={{
                                        background:
                                            'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)',
                                    }}></div>
                            </div>

                            <div className='relative space-y-6'>
                                <PasswordlessSignin
                                    hintEmail={
                                        isMsTeams
                                            ? msTeamsContext?.user?.loginHint
                                            : undefined
                                    }
                                />
                                <div className='mx-auto space-y-4 max-w-[320px] flex flex-col items-center justify-center'>
                                    <button
                                        onClick={handleMicrosoftAuthClick}
                                        title='Sign in with Microsoft'
                                        className='flex max-w-[300px] items-center justify-center w-full py-2 text-base  text-white transition-all duration-200 bg-gray-900 rounded-md font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-600 hover:text-white'>
                                        <MicrosoftLogo />
                                        Sign in with Microsoft
                                    </button>
                                    {!isMsTeams && (
                                        <GoogleAuthButton
                                            nextUrl={nextUrl}
                                            onAuthorizationStart={startFetching}
                                            onAuthorizationFinish={stopFetching}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='relative mt-6 md:mt-9'>
                            <div
                                className='absolute inset-0 flex items-center'
                                aria-hidden='true'>
                                <div className='w-full border-t border-gray-200 dark:border-gray-600'></div>
                            </div>
                        </div>

                        <p className='px-2 mt-8 md:mt-12 text-base font-normal leading-relaxed dark:text-gray-200 text-gray-600 font-pj'>
                            Having trouble with sign-in?{' '}
                            <a
                                href='https://docs.perfectwiki.com/Having-Trouble-Signing-In_zq1NnAFXAsWDFJN5lHhh'
                                target='_blank'
                                rel='noreferrer'
                                title='See common sign-in issues and solutions'
                                className='font-bold dark:text-gray-400 text-gray-900 rounded font-pj hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2'>
                                See common issues &amp; solutions
                            </a>
                        </p>
                    </div>
                </div>

                <div className='h-full hidden lg:grid relative  w-full px-4 py-12 overflow-hidden bg-gray-900 lg:max-w-2xl lg:px-20 xl:px-24 sm:px-6 place-items-center'>
                    <div className='absolute inset-0'>
                        <img
                            className='object-cover object-top w-full h-full scale-150 -rotate-90 opacity-10'
                            src={BackgroundPattern}
                            alt='Background Pattern'
                        />
                    </div>

                    <div className='relative max-w-sm mx-auto'>
                        <div className='inline-flex items-center justify-center w-20 h-20 bg-gray-800 rounded-xl'>
                            <svg
                                className='w-auto h-5 text-white'
                                viewBox='0 0 33 23'
                                fill='currentColor'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path d='M32.0011 4.7203L30.9745 0C23.5828 0.33861 18.459 3.41404 18.459 12.4583V22.8687H31.3725V9.78438H26.4818C26.4819 6.88236 28.3027 5.17551 32.0011 4.7203Z' />
                                <path d='M13.5421 4.7203L12.5155 0C5.12386 0.33861 0 3.41413 0 12.4584V22.8687H12.914V9.78438H8.02029C8.02029 6.88236 9.84111 5.17551 13.5421 4.7203Z' />
                            </svg>
                        </div>

                        <blockquote className='mt-14'>
                            <p className='text-2xl font-medium leading-relaxed text-white lg:text-3xl font-pj'>
                                “Better than the Teams Wiki, better than
                                SharePoint, better than OneNote.
                                <br />
                                Try it!”
                            </p>
                        </blockquote>
                        <div className='mt-12 flex items-center'>
                            <img
                                className='flex-shrink-0 object-cover rounded-full w-14 h-14'
                                src={ReviewerPhoto}
                                alt='Robin Steen - Head of the ICT department at ARhus'
                            />
                            <div className='ml-4'>
                                <div className='my-2'>
                                    <FiveStarRating />
                                </div>
                                <p className='text-xl font-bold text-white font-pj'>
                                    Robin Steen
                                </p>
                                <p className='mt-px text-lg font-normal text-gray-400 font-pj'>
                                    Head of the ICT department at ARhus
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default function Page() {
    const navigate = useNav();
    const { hash } = useLocation();
    const [state, setState] = useState({
        isFetching: true,
        nextUrl: '/',
        msTeamsContext: null,
    });

    const isFetching = state.isFetching;
    const nextUrl = state.nextUrl;

    const startFetching = useCallback(() => {
        setState({ ...state, isFetching: true });
    }, [state]);

    const stopFetching = useCallback(() => {
        setState({ ...state, isFetching: false });
    }, [state]);

    useEffect(() => {
        if (hash) {
            // if we found something like id=XXXXX in hash than we use it as post-hog id
            const id = hash.split('=');
            if (id.length > 1) {
                const postHogId = id[1];
                analytics.alias(postHogId);
            }
        }
    }, [hash]);

    useEffect(() => {
        function getNextUrlFromUrl() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                let nextUrlFromParams = urlParams.get('next');
                urlParams.delete('next');
                urlParams.delete('message');

                if (!nextUrlFromParams) {
                    return '/';
                }

                const nextUrl = new URL(
                    decodeURIComponent(nextUrlFromParams),
                    window.location.origin,
                );

                return nextUrl;
            } catch (err) {
                console.error(err);
                return undefined;
            }
        }

        function getErrorMessageFromUrl() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const message = urlParams.get('message');
                return message;
            } catch (err) {
                console.error(err);
                return undefined;
            }
        }

        async function showMainAuthPage({ nextUrl, msTeamsContext }) {
            const errorMessage = getErrorMessageFromUrl();

            setState({ isFetching: false, msTeamsContext, nextUrl });
            if (errorMessage) {
                AppNotification.error({
                    message: 'Error',
                    description: errorMessage,
                    duration: 10,
                    placement: 'topLeft',
                });
            }
        }

        async function initializeAuthentication() {
            const sessionId = hasSessionId();
            const nextUrl = getNextUrlFromUrl();

            if (sessionId) {
                navigate(nextUrl);
                return;
            }

            const msTeamsContext = await getMsTeamsContext();
            showMainAuthPage({ nextUrl, msTeamsContext });
        }

        initializeAuthentication();
    }, [navigate]);

    if (isFetching) {
        return <FullScreenLoading message={'Signing you in...'} />;
    }

    return (
        <SignInPage
            nextUrl={nextUrl}
            startFetching={startFetching}
            stopFetching={stopFetching}
            msTeamsContext={state.msTeamsContext}
        />
    );
}
