export const VISIBILITY_FILTERS = {
    VISIBLE: 'visible',
    HIDDEN: 'hidden',
    REMOVED: 'removed',
};

export function docStateToUserReadableFormat(docState) {
    switch (docState) {
        case VISIBILITY_FILTERS.VISIBLE:
            return 'Restored';
        case VISIBILITY_FILTERS.HIDDEN:
            return 'Moved to trash';
        case VISIBILITY_FILTERS.REMOVED:
            return 'Removed';
        default:
            return '';
    }
}

export const TEAM_ID = 'teamId';
export const CHANNEL_ID = 'channelId';
export const SUBENTITY_ID = 'subEntityId';

export const USER_ROLES = {
    ADMIN: 'admin',
    EDITOR: 'user',
    READER: 'reader',
    BLOCKED: 'blocked',
};
